.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-container {
  display: relative;
  justify-content: space-between;
  background-color: #333333;
  padding: 20px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.rune-header {
  display: flex;
  align-items: center;
}

.table-title {
  margin: 0;
  color: #ffffff;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 15px;
}

.custom-dropdown-arrow {
  margin-left: 10px;
}

.custom-dropdown-options {
  position: absolute;
  top: 100%;
  left: 2%;
  right: 0;
  border: 0px solid #ccc;
  border-radius: 10px;
  background-color: #222;
  z-index: 1000;
  width: 98%;
  font-size: 0.6em;
  max-height: 400px; /* Adjust this value as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}

.extra2 .custom-dropdown-options {
  position: absolute;
  top: 100%;
  left: 0%;
  right: 0;
  border: 0px solid #ccc;
  border-radius: 10px;
  background-color: #222;
  z-index: 1000;
  width: 150%;
  font-size: 0.6em;
  max-height: 400px; /* Adjust this value as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}

.custom-dropdown-search {
  background-color: #444;
  border-style: none;
  width: 150px;
  font-size: 1em;
  color: #fff;
}

.custom-dropdown-search::placeholder {
  color: #fff;
  text-align: center;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.custom-dropdown-selected{
  font-size: 0.6em;
}

.custom-dropdown-option {
  padding: 10px;
  cursor: pointer;
}

.custom-dropdown-option:hover {
  background-color: #111111;
}

.row {
  display: flex;
  width: 100%;
}

.row2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.holder-trends-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
}

.column1 {
  padding: 10px;
}

.extra {
  padding: 10px;
  background-color: #333333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.extra2 {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
}

.column2-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.column2 {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 48%;
  padding: 10px;
  box-sizing: border-box;
}

.chart-card {
  position: relative;
  background-color: #444;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  color: white;
  min-height: 144px; /* Set a fixed height */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure content is spaced evenly */
}

.chart-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 95%;
}

.holder-text {
  font-size: 0.5em;
  margin-top: 0px;
  margin-bottom: 15px;
}

.percent-text {
  font-size: 0.5em;
  margin-top: 0px;
  margin-bottom: px;
}

.remove-chart {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.2em;
  cursor: pointer;
}

.chart-card .card-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.chart-card .card-content {
  font-size: 1em;
}

.add-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-icon {
  font-size: 2em;
  font-weight: bold;
}

.empty-column {
  flex: 1;
  width: 20%;
}

h2 {
  font-size: .8em;
}

h3 {
  font-size: .6em;
}

.rune-dropdown {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  font-size: 16px;
}

.timeframe-dropdown {
  margin-top: 5px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  font-size: 0.5em
}

td {
  font-size: 0.5em
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .row {
    display: block !important;
}
.column2-container {
  display: block !important;
}
.table-container {
  padding: 0px !important;
}

.extra2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.column2 {
  width: 100% !important;
}

h3 {
  font-size: .9em;
}

.holder-text, .percent-text {
  font-size: .8em;
}

.custom-dropdown-selected {
  font-size: .8em;
}
}